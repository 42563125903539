import { Component, OnInit, Inject, DoCheck } from '@angular/core';
import { PageScrollInstance, PageScrollService, EasingLogic } from 'ngx-page-scroll';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"]
})
export class MenuComponent implements OnInit {
  language: string;
  firstNav: {
    bio: string,
    skills: string,
    portfolio: string,
    contact: string
  }
  sideNav: string;
  constructor(@Inject(DOCUMENT) private document: any,
    private pageScrollService: PageScrollService, public dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
    this.fnav();
    this.sideNav = localStorage.getItem('navside');
  }

  fnav() {
    if (this.language == 'gr') {
      this.firstNav = {
        bio: 'Βιογραφικό',
        skills: 'Ικανότητες',
        portfolio: 'Έργα - Βίντεο',
        contact: 'Επικοινωνία'
      }
    } else if (this.language == 'uk') {
      this.firstNav = {
        bio: 'Biography - CV',
        skills: 'Skills',
        portfolio: 'Portfolio - Videos',
        contact: 'Contact'
      }
    } else if (this.language == 'de') {
      this.firstNav = {
        bio: 'Lebenslauf',
        skills: 'Fähigkeit',
        portfolio: 'Portefeuille - Videos',
        contact: 'Kontakt'
      }
    }
  }

  changeLang($lang) {
    let key = 'Lang';
    localStorage.setItem(key, $lang);
    let myLang = localStorage.getItem(key);
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
    this.fnav();
    // alert(this.language);
  }

}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
export class DialogContentExampleDialog implements OnInit, DoCheck{
  
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }

  ngDoCheck(){
		let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
		}
	}

}
