import { Component, OnInit, DoCheck , Inject} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"]
})
export class BioComponent implements OnInit, DoCheck {
  language: string;
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    localStorage.setItem('navside', 'bio');
    let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
		}
  }

  ngDoCheck(){
		let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
    }
    if(this.language == 'gr'){
			this.titleService.setTitle('Βιογραφικό - Βασίλειος Τσούλης');
			this.meta.updateTag({ name: 'description', content: 'Βασίλειος Τσούλης Βιογραφικό' });
		}else{
			this.titleService.setTitle('CV - Vasilis Tsoulis');
			this.meta.updateTag({ name: 'description', content: 'Vasilis Tsoulis Biography CV resume' });
		}
	}

}
