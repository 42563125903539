import { Pipe, PipeTransform } from '@angular/core';
import { Mydata } from './portfolio/portfolio.component';

@Pipe({
  name: 'uniquePlatform'
})
export class UniquePlatformPipe implements PipeTransform {

  transform(_initialCollection: Mydata[], _platform:string): Mydata[] {
      let results = new Array<Mydata>();

      _initialCollection.forEach((product,index,collection) => {
        if(_platform === null || _platform || _platform === undefined){
             if(collection.map(product => product.platform).indexOf(product.platform) === index){
             	return results.push(product);
             }
             // console.log(_platform);
        }
      }); 
    return results;
  }

}
