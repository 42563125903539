import { Component, OnInit, HostListener, DoCheck , Inject} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"],
  host: {
      '(window:scroll)': 'updateHeader($event)'
  }
})
export class HomeComponent implements OnInit, DoCheck {
	language: string;
  isScrolled = false;
	currPos: Number = 0;
	startPos: Number = 0;
	changePos: Number = 100;
	// slideIndex: Number = 0;
  private sub: Subscription;
  private fragment: String;
  
  // private element: String;
private carouselTimeoutVar : any;
introText = "Welcome";

@HostListener("window:resize", ["$event"])
onResize(event) {      
  this.introText = window.innerWidth < 1080 ? '<i fa class="fas fa-home"></i> <h1>1</h1>' : "Welcome";
}	

//alternative of hostlistener with getter
// get introText(): string {
//   return window.innerWidth < 1080 ? "New" : "Welcome";
// }


  constructor(private route: ActivatedRoute, private titleService: Title, private meta: Meta) {
  	this.sub = route.fragment.pipe(filter(f => !!f)).subscribe(f => document.getElementById(f).scrollIntoView() );
  	// this.onResize();
	}

  cleanurl(){
    // window.history.pushState('page2', 'Title', '/');
    // return false;
    // window.history.back();
    console.log('back');
  }
 
  public ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }
    clearTimeout(this.carouselTimeoutVar);
  }

  ngOnInit() {
		let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
		}				
		
  	let slideIndex = 0;	

	let carousel = ()=> {
	    let i;
	    let x = <HTMLElement[]><any>document.getElementsByClassName("titleweb");
	    let y = <HTMLElement[]><any>document.getElementsByClassName("devimages");
	    let z = <HTMLElement[]><any>document.getElementsByClassName("devlogoimages");
	    let dx = <HTMLElement[]><any>document.getElementsByClassName("titlewebdes");
	    let dy = <HTMLElement[]><any>document.getElementsByClassName("photodesignclass");
	    let dz = <HTMLElement[]><any>document.getElementsByClassName("deslogoimages");

	    for (i = 0; i < Object.keys(x).length; i++) {
	      x[i].style.backgroundColor = "black";
	      x[i].style.color = "white";
	      dx[i].style.backgroundColor = "black";
	      dx[i].style.color = "white";
	      y[i].style.opacity = "0";
	      z[i].style.opacity = "0";
	      dy[i].style.opacity = "0";
	      dz[i].style.opacity = "0";
	    }
	    slideIndex++;
	    if (slideIndex > Object.keys(x).length) {slideIndex = 1} 
	    x[slideIndex-1].style.backgroundColor = "#ff6600";
		x[slideIndex-1].style.color = "black";
		dx[slideIndex-1].style.backgroundColor = "#ff6600";
		dx[slideIndex-1].style.color = "black";
	    y[slideIndex-1].style.opacity = "1";
	    z[slideIndex-1].style.opacity = "1";
	    dy[slideIndex-1].style.opacity = "1";
	    dz[slideIndex-1].style.opacity = "1";
	    // setTimeout(carousel, 3000); 
	    this.carouselTimeoutVar = setTimeout(carousel, 3000);
	}
	carousel();

	// if(this.screenWidth < 1921){
	// 	alert(this.screenWidth);
  // }
	}
	
	ngDoCheck(){
		let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
		}
		if(this.language == 'gr'){
			this.titleService.setTitle('Αρχική - Βασίλειος Τσούλης');
			this.meta.updateTag({ name: 'description', content: 'Βασίλειος Τσούλης Προγραμματιστής και δημιουργία σχεδίασης ιστοσελίδων' });
		}else{
			this.titleService.setTitle('Home Page - Vasilis Tsoulis');
			this.meta.updateTag({ name: 'description', content: 'Vasilis Tsoulis Web Developer & Web designer' });
		}
	}
  
  updateHeader(evt) {
  	    let develop = document.getElementById('developer-info').offsetHeight;
        this.currPos = (window.pageYOffset || evt.target.scrollTop) - (evt.target.clientTop || 0);
        // if(this.currPos >= this.changePos) {
				// console.log(window.innerWidth);

        if(window.pageYOffset >= 150) {
            // console.log(this.currPos + ' ' + window.pageYOffset);
            
            if(window.pageYOffset > 250 && window.pageYOffset <= 800 && window.innerWidth > 800){
            	document.getElementById("dev1").style.opacity = '1';
	            document.getElementById("dev2").style.opacity = '1';
	            document.getElementById("dev3").style.opacity = '0';
	            document.getElementById("dev4").style.opacity = '0';
	            document.getElementById("dev1").style.transform = 'translate(0px, -'+ window.pageYOffset /25 +'%)';
	            document.getElementById("dev2").style.transform = 'translate(0px, '+ window.pageYOffset /20 +'%)';
	            document.getElementById("intro-back-image").style.transform = 'translate(0px, -'+ window.pageYOffset /25 +'%)';
	            if(window.pageYOffset > 400 && window.pageYOffset <= 500){
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 1)';
	            }
	            else if(window.pageYOffset > 500 && window.pageYOffset <= 650) {
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0.75)';
	            }else if(window.pageYOffset > 650 && window.pageYOffset <= 800){
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0.5)';
	            }
						}
						else if(window.pageYOffset > 800 && window.innerWidth > 800){
            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0)';
            	document.getElementById("dev1").style.cssText = 'transform: translate(0px, -1200px); opacity: 0;';
            	document.getElementById("dev2").style.cssText = 'transform: translate(0px, 1200px); opacity: 0;';
            	document.getElementById("dev3").style.opacity = '1';
	            document.getElementById("dev4").style.opacity = '1';        
						}
						else if(window.pageYOffset > 500 && window.pageYOffset <= 1050 && window.innerWidth <= 800){
							document.getElementById("intro-back-image").style.transform = 'translate(0px, -'+ window.pageYOffset /23 +'%)';
						}
						else if(window.pageYOffset > 950 && window.pageYOffset <= 1300 && window.innerWidth <= 800){						
							document.getElementById("dev1").style.opacity = '1';
	            document.getElementById("dev2").style.opacity = '1';
	            document.getElementById("dev3").style.opacity = '0';
	            document.getElementById("dev4").style.opacity = '0';
	            document.getElementById("dev1").style.transform = 'translate(0px, -'+ window.pageYOffset /25 +'%)';
							document.getElementById("dev2").style.transform = 'translate(0px, '+ window.pageYOffset /20 +'%)';
						}
						else if(window.pageYOffset > 1300 && window.pageYOffset < 1550 && window.innerWidth <= 800){							
							// console.log(window.pageYOffset);
							if(window.pageYOffset > 1300 && window.pageYOffset <= 1400){							
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 1)';
	            }
	            else if(window.pageYOffset > 1400 && window.pageYOffset <= 1480) {
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0.75)';
	            }else if(window.pageYOffset > 1480 && window.pageYOffset <= 1550){
	            	document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0.5)';
	            }
            	document.getElementById("dev1").style.cssText = 'transform: translate(0px, -' +window.pageYOffset /20+'px); opacity: 0;';
            	document.getElementById("dev2").style.cssText = 'transform: translate(0px, '+ window.pageYOffset /15 +'px); opacity: 0;';
            	document.getElementById("dev3").style.opacity = '1';
	            document.getElementById("dev4").style.opacity = '1'; 
								
						}
						else if(window.pageYOffset > 1550 && window.innerWidth <= 800){				
							document.getElementById("blackround").style.backgroundColor = 'rgba(0,0,0, 0)';
            	document.getElementById("dev1").style.cssText = 'transform: translate(0px, -2200px); opacity: 0;';
            	document.getElementById("dev2").style.cssText = 'transform: translate(0px, 2200px); opacity: 0;';
            	document.getElementById("dev3").style.opacity = '1';
	            document.getElementById("dev4").style.opacity = '1'; 
						}					

            this.isScrolled = true;
        } 
        else {
            this.isScrolled = false;         
        }
   
    }    
}