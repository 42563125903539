import { Component, OnInit, DoCheck , Inject} from '@angular/core';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, delay, map} from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

export class State {
  constructor( 
              public skills: number, 
              public flag: string, public name: string
              ) {}
}

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"]
})
export class SkillsComponent implements OnInit, DoCheck {
  language: string;
  constructor(private titleService: Title, private meta: Meta,
    ) {
  	this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        delay(0),
        map(state => state ? this.filterStates(state) : this.states.slice())
      );
  }

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
    }    
  }

  ngDoCheck(){
		let myLang = localStorage.getItem('Lang');
    if(myLang){
      this.language = myLang;
    }else{
      this.language = 'gr';
    }    
    if(this.language == 'gr'){
			this.titleService.setTitle('Ικανότητες - Βασίλειος Τσούλης');
			this.meta.updateTag({ name: 'description', content: 'Βασίλειος Τσούλης Ικανότητες και δεξιότητες δημιουργίας ιστοσελίδων' });
		}else{
			this.titleService.setTitle('Skills - Vasilis Tsoulis');
			this.meta.updateTag({ name: 'description', content: 'Vasilis Tsoulis Skills and Abilities creation of websites and development' });
		}
  } 

  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;
  searchValue:string = '';
  clearSearch() {
    this.searchValue = null;
  }

  states: State[] = [
    {
      name: 'Laravel & Lumen',
      skills: 95,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/LaravelLogo.png/120px-LaravelLogo.png'
    },
    {
      name: 'Php',
      skills: 95,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/300px-PHP-logo.svg.png'
    },
    {
      name: 'NodeJs',
      skills: 90,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/220px-Node.js_logo.svg.png'
    },
    {
      name: 'Javascript',
      skills: 92,
      flag: 'https://static1.squarespace.com/static/58c8472ad2b857863d5c69eb/t/5967bcea099c0122c5b8f8a2/1499970830578/javascript-logo-whitebg.png?format=300w'
    },
    {
      name: 'Html5',
      skills: 98,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/120px-HTML5_logo_and_wordmark.svg.png'
    },
    {
      name: 'CSS3',
      skills: 98,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/363px-CSS3_logo_and_wordmark.svg.png'
    },
    {
      name: 'AngularJS',
      skills: 90,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/AngularJS_logo.svg/250px-AngularJS_logo.svg.png'
    },
    {
      name: 'Angular 5+',
      skills: 85,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/250px-Angular_full_color_logo.svg.png'
    },
    {
      name: 'Firebase',
      skills: 90,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Firebase_logo1.png'
    },
    {
      name: '.NET MVC',
      skills: 60,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Microsoft_.NET_logo.png/120px-Microsoft_.NET_logo.png'
    },
    {
      name: 'C#',
      skills: 65,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/C_Sharp_wordmark.svg/150px-C_Sharp_wordmark.svg.png'
    },
    {
      name: 'CodeIgniter',
      skills: 80,
      flag: 'https://upload.wikimedia.org/wikipedia/en/7/7c/CodeIgniter.png'
    },
    {
      name: 'Python',
      skills: 70,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Python_logo_and_wordmark.svg/200px-Python_logo_and_wordmark.svg.png'
    },
    {
      name: 'Djanco',
      skills: 40,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Django_logo.svg/185px-Django_logo.svg.png'
    },
    {
      name: 'Ruby On Rails',
      skills: 40,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ruby_On_Rails_Logo.svg/150px-Ruby_On_Rails_Logo.svg.png'
    },
    {
      name: 'JQuery',
      skills: 90,
      flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/JQuery_logo.svg/220px-JQuery_logo.svg.png'
    },
    {
      name: 'Json',
      skills: 100,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/JSON_vector_logo.svg/160px-JSON_vector_logo.svg.png'
    },
    {
      name: 'Wordpress',
      skills: 55,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/WordPress_logo.svg/540px-WordPress_logo.svg.png'
    },
    {
      name: 'Photoshop',
      skills: 90,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/66px-Adobe_Photoshop_CC_icon.svg.png'
    },
    {
      name: 'Illustrator',
      skills: 80,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/66px-Adobe_Illustrator_CC_icon.svg.png'
    },
    {
      name: 'After Effects',
      skills: 45,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Adobe_After_Effects_CC_icon.svg/66px-Adobe_After_Effects_CC_icon.svg.png'
    },
    {
      name: 'In Design',
      skills: 75,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Adobe_InDesign_CC_icon.svg/64px-Adobe_InDesign_CC_icon.svg.png'
    },
    {
      name: '3ds Max',
      skills: 35,
      flag: 'https://vignette.wikia.nocookie.net/logopedia/images/0/0c/3ds_Max_2017_lockup_OL_stacked_no_year.png/revision/latest/scale-to-width-down/300?cb=20161227144344'
    },
    {
      name: 'TypeScript',
      skills: 85,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/TypeScript_Logo.svg/220px-TypeScript_Logo.svg.png'
    },
    {
      name: 'Windows Server',
      skills: 70,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Windows_darkblue_2012_svg.svg/250px-Windows_darkblue_2012_svg.svg.png'
    },
    {
      name: 'Linux Server',
      skills: 80,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/150px-Tux.svg.png'
    },
    {
      name: 'Cisco Networks Associate',
      skills: 80,
      flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Cisco_logo.svg/200px-Cisco_logo.svg.png'
    }
  ];
  

  filterStates(name: string) {
    return this.states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

}
