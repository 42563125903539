import { Component, OnInit, DoCheck } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Title, Meta } from '@angular/platform-browser';

export class Mydata {
  id: number;
  name: string;
  category: string;
  platform: string;
  logo: string;
  link: string;
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"]
})
export class PortfolioComponent implements OnInit, DoCheck {
  language: string;
  // private hasScooterChckbx = true;
  platform: string = null;
  category: string = null;
  cleanPlatform: string = '';
  cleanCategory: string = '';
  public resultCollection = new Array<Mydata>();
  // private platformCollection = new Array<Platform>();
  panelMargin = new FormControl();
  constructor(public dialog: MatDialog, private titleService: Title, private meta: Meta) {
    this.resultCollection = this.generateResults();
  }

  openDialog(id) {

    if (id == 1) {
      const dialogRef = this.dialog.open(ITech, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    } else if (id == 2) {
      const dialogRef = this.dialog.open(Crm, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (id == 6) {
      const dialogRef = this.dialog.open(Tictac, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else if (id == 7) {
      const dialogRef = this.dialog.open(RentApart, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    } else if (id == 8) {
      const dialogRef = this.dialog.open(Poker, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else {
      const dialogRef = this.dialog.open(Poker, {
        width: '800px',
        maxWidth: '90%',
        height: '85%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }


  }

  generateResults(): Mydata[] {
    return [
      {
        id: 1,
        name: "i-tech",
        category: "e-shop",
        platform: "Laravel",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/LaravelLogo.png/120px-LaravelLogo.png",
        link: ""
      },
      {
        id: 2,
        name: "AutoCRM",
        category: "CRM",
        platform: "NodeJS",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/220px-Node.js_logo.svg.png",
        link: ""
      },
      {
        id: 3,
        name: "Tourist Hotel",
        category: "Blog",
        platform: "Wordpress",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/WordPress_logo.svg/540px-WordPress_logo.svg.png",
        link: "https://www.touristhotel.gr/"
      },
      {
        id: 4,
        name: "sdkth",
        category: "Blog",
        platform: "Laravel",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/LaravelLogo.png/120px-LaravelLogo.png",
        link: "http://www.sdkth.gr/"
      },
      {
        id: 5,
        name: "Lioumis",
        category: "Catalog",
        platform: "Php",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/300px-PHP-logo.svg.png",
        link: "http://www.epiplalioumis.gr/"
      },
      {
        id: 6,
        name: "Tic Tac Toe",
        category: "Game",
        platform: "Laravel",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/LaravelLogo.png/120px-LaravelLogo.png",
        link: ""
      },
      {
        id: 7,
        name: "Rent Apartment",
        category: "Real Estate",
        platform: "Codeigniter",
        logo: "https://upload.wikimedia.org/wikipedia/en/7/7c/CodeIgniter.png",
        link: ""
      },
      {
        id: 8,
        name: "Hi-Lo & Poker",
        category: "game",
        platform: "AngularJS",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/AngularJS_logo.svg/250px-AngularJS_logo.svg.png",
        link: ""
      }
    ];
  }

  clearSearchAll() {
    this.category = undefined;
    this.platform = undefined;
    this.cleanPlatform = null;
    this.cleanCategory = null;
  }

  ngOnInit() {
    localStorage.setItem('navside', 'bio');
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
    if(this.language == 'gr'){
			this.titleService.setTitle('Έργα & Βίντεο - Βασίλειος Τσούλης');
			this.meta.updateTag({ name: 'description', content: 'Βασίλειος Τσούλης Έργα και πρότζεκτ. Βίντεο επίδειξης τεχνικών γνώσεων.' });
		}else{
			this.titleService.setTitle('Portfolio & Video - Vasilis Tsoulis');
			this.meta.updateTag({ name: 'description', content: 'Vasilis Tsoulis Portfolio & Video of my projects showing my abilities' });
		}
  }

}

@Component({
  selector: 'i-tech',
  templateUrl: 'i-tech.html',
})
export class ITech implements OnInit, DoCheck {
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
}

@Component({
  selector: 'rent-apart',
  templateUrl: 'rent-apart.html',
})
export class RentApart implements OnInit, DoCheck {
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
}

@Component({
  selector: 'tic-tac',
  templateUrl: 'tic-tac.html',
})
export class Tictac implements OnInit, DoCheck {
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
}

@Component({
  selector: 'crm',
  templateUrl: 'crm.html',
})
export class Crm implements OnInit, DoCheck {
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
}

@Component({
  selector: 'poker',
  templateUrl: 'poker.html',
})
export class Poker implements OnInit, DoCheck {
  language: string;

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
}
