import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatCardModule,
  MatBottomSheetModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatTabsModule,
  MatSelectModule,
  MatDialogModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatDividerModule,
  MatSortModule,
  MatTableModule,
  MatStepperModule,
  MatPaginatorModule,
  MatMenuModule,
  MatAutocompleteModule
} from '@angular/material';

// import {MatMomentDateModule,MomentDateAdapter} from '@angular/material-moment-adapter';
@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatCardModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatDividerModule,
    MatSortModule,
    MatTableModule,
    MatStepperModule,
    MatPaginatorModule,
    MatMenuModule,
    MatAutocompleteModule
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatCardModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatDividerModule,
    MatSortModule,
    MatTableModule,
    MatStepperModule,
    MatPaginatorModule,
    MatMenuModule,
    MatAutocompleteModule
  ]
})
export class MaterialModule {}
