import { Pipe, PipeTransform } from '@angular/core';
import { Mydata } from './portfolio/portfolio.component';

@Pipe({
  name: 'uniqueCategory'
})
export class UniqueCategoryPipe implements PipeTransform {

  transform(_initialCollection: Mydata[], _category: string): Mydata[] {
      let results = new Array<Mydata>();

      _initialCollection.forEach((product,index,collection) => {
        if(_category === null || _category || _category === undefined){            
             if(collection.map(product => product.category).indexOf(product.category) === index){
             	return results.push(product);
             }
        }

      }); 
    	return results;   
  }

}
