import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxPageScrollModule } from 'ngx-page-scroll';
import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { MenuComponent, DialogContentExampleDialog } from './menu/menu.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './home/home.component';
import { BioComponent } from './bio/bio.component';
import { SkillsComponent } from './skills/skills.component';
import { PortfolioComponent, ITech, RentApart, Tictac, Crm, Poker } from './portfolio/portfolio.component';
import { FilterPipe } from './filter.pipe';
import { UniquePlatformPipe } from './unique-platform.pipe';
import { UniqueCategoryPipe } from './unique-category.pipe';
import { ContactComponent } from './contact/contact.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    BioComponent,
    DialogContentExampleDialog,
    SkillsComponent,
    PortfolioComponent,
    FilterPipe,
    UniquePlatformPipe,
    UniqueCategoryPipe,
    ITech,
    RentApart,
    Tictac,
    Crm,
    Poker,
    ContactComponent
  ],
  imports:[
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxPageScrollModule,
    Angular2FontawesomeModule
  ],
  entryComponents: [
    DialogContentExampleDialog,
    ITech,
    RentApart,
    Tictac,
    Crm,
    Poker
  ],
  providers: [Title, Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
