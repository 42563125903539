import { Pipe, PipeTransform } from '@angular/core';
import { Mydata } from './portfolio/portfolio.component';

@Pipe({
  name: 'FilterPipe'
})

export class FilterPipe implements PipeTransform {

  transform(_initialCollection: Mydata[], _category: string, _platform:string): Mydata[] {
    let results = new Array<Mydata>();

      _initialCollection.forEach((person,index,collection) => {
      	if((_category === null && _platform === null) || (_category === null && _platform === undefined) || (_category === undefined && _platform === null) || (_category === undefined && _platform === undefined)){
	  
			return results.push(person);
	    }
      	if(_category && _platform){
	        if( person.category.toLowerCase().includes(_category.toLowerCase()) && person.platform.toLowerCase().includes(_platform.toLowerCase()) ){
	          results.push(person);
	        }
	    }
	    else if(_category){
	        if( person.category.toLowerCase().includes(_category.toLowerCase()) ){
	          results.push(person);
	        }
	    }
	    else if(_platform){
	    	if( person.platform.toLowerCase().includes(_platform.toLowerCase()) ){
	          results.push(person);
	        }
	    }	    
      }); 
    return results;
  }

}
