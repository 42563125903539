import { Component, OnInit, DoCheck , Inject} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', "../../../node_modules/font-awesome/css/font-awesome.css"]
})
export class ContactComponent implements OnInit, DoCheck {
  language: string;
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
  }
  ngDoCheck() {
    let myLang = localStorage.getItem('Lang');
    if (myLang) {
      this.language = myLang;
    } else {
      this.language = 'gr';
    }
    if(this.language == 'gr'){
			this.titleService.setTitle('Επικοινωνία - Βασίλειος Τσούλης');
			this.meta.updateTag({ name: 'description', content: 'Βασίλειος Τσούλης Επικοινωνήστε μαζί μου για περισσότερες πληροφορίες ιστοσελίδων.' });
		}else{
			this.titleService.setTitle('Contact me - Vasilis Tsoulis');
			this.meta.updateTag({ name: 'description', content: 'Vasilis Tsoulis Contact with me for more information about websites' });
		}
  }

}
